<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <!--begin::Form-->
                        <form class="form" novalidate="novalidate" id="kt_save_changes_form">
                            <!--begin::Header-->
                            <div class="card-header border-0 pt-5">
                                <div class="card-title align-items-start flex-column">
                                    <h3 class="font-weight-bolder text-dark fs-15">Create Blog Post</h3>
                                </div>
                            </div>
                            <!--end::Header-->
                            <!--begin::Body-->
                            <div class="card-body">
                                <div class="row">
                                    <label class="col-xl-3"></label>
                                    <div class="col-lg-9 col-xl-6">
                                        <h5 class="font-weight-bold mb-6">Information</h5>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Title</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input ref="title" name="title"
                                            class="form-control form-control-lg form-control-solid" type="text"
                                            v-model="form.title" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Category</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <b-form-select ref="category" name="category" v-model="form.category"
                                            :options="options" class="form-control form-control-lg form-control-solid">
                                        </b-form-select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Tags</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <b-form-tags ref="tags" name="tags" input-id="tags-basic" v-model="form.tags"
                                            class="form-control form-control-lg form-control-solid"></b-form-tags>
                                        <small tabindex="-1" id="input-group-1__BV_description_"
                                            class="form-text text-dark">Type a new tag and press enter.</small>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Publish Date</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <b-form-datepicker ref="date" name="date" v-model="form.date"
                                            class="form-control form-control-lg form-control-solid"></b-form-datepicker>
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-xl-3"></label>
                                    <div class="col-lg-9 col-xl-6">
                                        <h5 class="font-weight-bold mt-10 mb-6">Article</h5>
                                    </div>
                                </div>
                                <div class="form-group row justify-content-center">
                                    <div class="col-lg-7">
                                        <Editor api-key="iqpnxbe2taa5im0ui17d55fkzatlusodsi68e9iqiwroj68z"
                                            v-model="form.body"
                                            :init="{ plugins: 'lists link image table code help wordcount' }" />
                                    </div>
                                </div>
                                <div class="row">
                                    <label class="col-xl-3"></label>
                                    <div class="col-lg-9 col-xl-6">
                                        <h5 class="font-weight-bold mt-10 mb-6">Images</h5>
                                    </div>
                                </div>
                                <!--div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Blog Picture for Home
                                        Page<br />324X464</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_smphoto">
                                            <div class="image-input-wrapper"
                                                :style="{ backgroundImage: `url(${smphoto})`, width: `120px`, height: `172px` }">
                                            </div>
                                            <label class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="change" data-toggle="tooltip" title=""
                                                data-original-title="Change small photo">
                                                <i class="fa fa-pen icon-sm text-dark"></i>
                                                <input type="file" name="smphoto" accept=".png, .jpg, .jpeg"
                                                    @change="onSmFileChange($event)" />
                                                <input type="hidden" name="profile_smphoto_remove" />
                                            </label>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="cancel" data-toggle="tooltip" title="Cancel small photo">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="remove" data-toggle="tooltip" title="Remove small photo" @click="current_sm_photo = null; form.smphoto = null;
                                    ">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                        </div>
                                        <span class="form-text text-dark">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Small Blog Picture on
                                        Category
                                        Page<br />415X464</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_mdphoto">
                                            <div class="image-input-wrapper"
                                                :style="{ backgroundImage: `url(${mdphoto})`, width: `120px`, height: `134px` }">
                                            </div>
                                            <label class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="change" data-toggle="tooltip" title=""
                                                data-original-title="Change medium photo">
                                                <i class="fa fa-pen icon-sm text-dark"></i>
                                                <input type="file" name="mdphoto" accept=".png, .jpg, .jpeg"
                                                    @change="onMdFileChange($event)" />
                                                <input type="hidden" name="profile_mdphoto_remove" />
                                            </label>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="cancel" data-toggle="tooltip" title="Cancel medium photo">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="remove" data-toggle="tooltip" title="Remove small photo" @click="
    current_md_photo = null;
form.mdphoto = null;
                                    ">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                        </div>
                                        <span class="form-text text-dark">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div-->
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Lead In Picture on
                                        Individual
                                        Article<br />746X446</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_lgphoto">
                                            <div class="image-input-wrapper">
                                                <img class="image-input-wrapper uploadimg" :src="lgphoto"  />
                                            </div>
                                            <label class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="change" data-toggle="tooltip" title=""
                                                data-original-title="Change large photo">
                                                <i class="fa fa-pen icon-sm text-dark"></i>
                                                <input type="file" name="lgphoto" accept=".png, .jpg, .jpeg"
                                                    @change="onLgFileChange($event)" />
                                                <input type="hidden" name="profile_lgphoto_remove" />
                                            </label>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="cancel" data-toggle="tooltip" title="Cancel large photo">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="remove" data-toggle="tooltip" title="Remove large photo" @click="
    current_lg_photo = null;
form.lgphoto = null;
                                    ">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                        </div>
                                        <span class="form-text text-dark">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Lead In picture name
                                        <br>
                                        Note: Must upload new photo to change name
                                        <br>
                                        Leave off the file extension
                                        <br>
                                        No spaces
                                    </label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input ref="url1" name="url1"
                                            class="form-control form-control-lg form-control-solid" type="text"
                                            v-model="form.url1" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Large Blog Picture on
                                        Category
                                        Page<br />858X425</label>
                                    <div class="col-lg-9 col-xl-6">
                                        <div class="image-input image-input-outline" id="kt_xlphoto">
                                            <div class="image-input-wrapper">
                                                <img class="image-input-wrapper uploadimg" :src="xlphoto"  />
                                            </div>
                                            <label class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="change" data-toggle="tooltip" title=""
                                                data-original-title="Change xlarge photo">
                                                <i class="fa fa-pen icon-sm text-dark"></i>
                                                <input type="file" name="lgphoto" accept=".png, .jpg, .jpeg"
                                                    @change="onXlFileChange($event)" />
                                                <input type="hidden" name="profile_xlphoto_remove" />
                                            </label>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="cancel" data-toggle="tooltip" title="Cancel large photo">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                            <span class="
                                        btn
                                        btn-xs
                                        btn-icon
                                        btn-circle
                                        btn-white
                                        btn-hover-text-primary
                                        btn-shadow
                                    " data-action="remove" data-toggle="tooltip" title="Remove xlarge photo" @click="
    current_xl_photo = null;
form.xlphoto = null;
                                    ">
                                                <i class="ki ki-bold-close icon-xs text-dark"></i>
                                            </span>
                                        </div>
                                        <span class="form-text text-dark">Allowed file types: png, jpg, jpeg.</span>
                                    </div>
                                </div>
                                <div class="pb-lg-0 pb-5">
                                    <div class="card-footer">
                                        <button ref="kt_save_changes" class="btn btn-ingomublue mr-2">
                                            Save
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-xl-3 col-lg-3 col-form-label text-right">Large Blog Picture name
                                        <br>
                                        Note: Must upload new photo to change name
                                        <br>
                                        Leave off the file extension
                                        <br>
                                        No spaces
                                    </label>
                                    <div class="col-lg-9 col-xl-6">
                                        <input ref="url2" name="url2"
                                            class="form-control form-control-lg form-control-solid" type="text"
                                            v-model="form.url2" />
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Editor from '@tinymce/tinymce-vue'

export default {
    name: "CreateBlog",
    data() {
        return {
            form: {
                title: "",
                category: [],
                tags: [],
                date: "",
                body: "",
                smphoto: null,
                mdphoto: null,
                lgphoto: null,
                xlphoto: null,
                url1: "",
                url2: ""
            },
            options: [],
            default_sm_photo: "/media/users/324_464.png",
            current_sm_photo: null,
            default_md_photo: "/media/users/415_464.png",
            current_md_photo: null,
            default_lg_photo: "/media/users/746_446.png",
            current_lg_photo: null,
            default_xl_photo: "/media/users/858_425.png",
            current_xl_photo: null
        }
    },
    components: {
        Editor
    },
    computed: {
        ...mapGetters(["catList"]),
        smphoto() {
            return this.current_sm_photo == null || this.current_sm_photo == "undefined"
                ? this.default_sm_photo
                : this.current_sm_photo;
        },
        mdphoto() {
            return this.current_md_photo == null || this.current_md_photo == "undefined"
                ? this.default_md_photo
                : this.current_md_photo;
        },
        lgphoto() {
            return this.current_lg_photo == null || this.current_lg_photo == "undefined"
                ? this.default_lg_photo
                : this.current_lg_photo;
        },
        xlphoto() {
            return this.current_xl_photo == null || this.current_xl_photo == "undefined"
                ? this.default_xl_photo
                : this.current_xl_photo;
        },
    },
    mounted() {
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: 1 });
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 1 });
        this.$store.dispatch("getCategoryList");
        const changes_form = KTUtil.getById("kt_save_changes_form");

        this.fv = formValidation(changes_form, {
            fields: {
                title: {
                    validators: {
                        notEmpty: {
                            message: "Title is required",
                        },
                    },
                },
                category: {
                    validators: {
                        notEmpty: {
                            message: "Category is required",
                        },
                    },
                },
                date: {
                    validators: {
                        notEmpty: {
                            message: "Date is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("insertBlogArticle", this.form)
                .then(() => {
                    this.$router.push({ name: 'blog' })
                });
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    methods: {
        onSmFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_sm_photo = event.target.result;
                    this.form.smphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onMdFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_md_photo = event.target.result;
                    this.form.mdphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onLgFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_lg_photo = event.target.result;
                    this.form.lgphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        onXlFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_xl_photo = event.target.result;
                    this.form.xlphoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
    },
    watch: {
        catList(newValue) {
            let newOptions = []
            newValue.forEach(element => {
                newOptions.push({ value: element.category, text: element.category })
            })
            this.options = newOptions;
        }
    }
};
</script>
<style scoped>
.uploadimg {
    width: 120px;
    object-fit: contain;
    background-color: black;
}
</style>
